.container.contact__container {
  display: grid;
  grid-template-columns: 35% 53%;
  gap: 12%;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option img {
  display: inline-block;
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

#message {
  display: none;
}

#form_message {
  background: #b1e7b1;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: bold;
  text-align: center;
  color: var(--color-bg-variant);
}

#form_error_message {
  background: #ff6666;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: bold;
  text-align: center;
  color: var(--color-white);
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

input:-webkit-autofill,
textarea:-webkit-autofill {
  box-shadow: 100vh 0 0 0 var(--color-bg-variant) inset !important;
  -webkit-text-fill-color: white !important;
}

/* ======================= Media Queries (Tablets) ======================= */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .contact__options {
    margin-top: 0;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill {
    -webkit-text-fill-color: black !important;
  }
}

/* ======================= Media Queries (Smart Phones) ======================= */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }

  .contact__options {
    margin-top: 0;
  }

  input,
  textarea {
    font-size: 1.01rem;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill {
    -webkit-text-fill-color: black !important;
  }
}
