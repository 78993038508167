header {
  height: 100vh;
  padding-top: 6rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}
/* ======================= CTA ======================= */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
/* ======================= Header Socials ======================= */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}
/* ======================= img ======================= */
.me {
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  width: 22rem;
  height: 23.5rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 11% 89% 15% 85% / 87% 17% 83% 13%;
  overflow: hidden;
  padding: 0.5rem;
}
/* ======================= Scroll Down ======================= */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  cursor: pointer;
}
/* ======================= Media Queries (Tablets) ======================= */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1100px) {
  header {
    height: 73vh;
  }
}

/* ======================= Media Queries (Smart Phones) ======================= */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .me {
    width: 18rem;
    height: 18rem;
    left: calc(50% - 9rem);
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}

@media screen and (max-width: 600px) and (max-height: 950px) {
  header {
    height: 90vh;
  }

  .me {
    width: 18rem;
    height: 18rem;
    left: calc(50% - 9rem);
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}

@media screen and (max-width: 600px) and (max-height: 668px) {
  header {
    height: 112vh;
  }

  .me {
    width: 18rem;
    height: 18rem;
    left: calc(50% - 9rem);
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
